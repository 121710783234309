import React from "react";
import { graphql } from "gatsby";

import Layout from "../../../shared/src/components/layout";
import Head from "../../../shared/src/components/head";

const NotFoundPage = ({ data, location }) => {
  const { title, menu } = data.site.siteMetadata;

  return (
    <Layout location={location} title={title} menu={menu}>
      <Head title="404: Not Found" />
      <h1>
        <span>Not Found</span>
      </h1>
      <p>You just hit a route that doesnʼt exist. Sorry about that.</p>
    </Layout>
  );
};

export default NotFoundPage;

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
        menu
      }
    }
  }
`;
